<template>
  <span>
    <br />
    <div class="container w1200">
      <!-- Consumer plans! -->

      <div v-if="!business">
        <div class="has-text-centered">
          <b-field>
            <b-switch v-model="yearly" type="is-success">
              <span class="tag is-success is-light"> Save 20% ∙ Pay annually </span>
            </b-switch>
          </b-field>
        </div>

        <br /><br />
        <div class="is-centered text-align-center" v-if="coupon">
          <div class="notification is-info is-light" style="max-width: 500px; margin: auto">
            Your coupon <code>{{ coupon }}</code> will be applied automatically at checkout.
          </div>
          <br /><br />
        </div>

        <div class="columns is-3 is-variable topsplit">
          <div
            class="column"
            :class="hideFree ? 'is-one-third' : 'is-one-quarter'"
            v-for="plan in plans"
            :key="plan.id"
          >
            <div class="box plan" :data-product="plan.id" :class="{ featured: plan.featured }">
              <img :src="plan.image" />
              <div class="wr">
                <h3 class="title is-3 k">{{ plan.title }}</h3>

                <template v-if="plan.isFree">
                  <p class="plan-price-desc">
                    <span>No base monthly fees </span>
                    <br />
                  </p>
                </template>

                <template v-else>
                  <p class="plan-price-desc annual" v-show="yearly">
                    <span
                      ><strong>${{ plan.yearlyPrice / 12 }}</strong> per month</span
                    >
                    <br />
                    <span class="plan-subtitle"
                      ><span class="price-year"></span> ${{ plan.yearlyPrice }} billed annually
                    </span>
                  </p>
                  <p class="plan-price-desc monthly" v-show="!yearly">
                    <span
                      ><strong>${{ plan.monthlyPrice }}</strong> per month</span
                    >
                    <br />
                    <span class="plan-subtitle">Billed monthly</span>
                  </p>
                </template>
                <strong v-if="!plan.isFree">Previous plan, plus:</strong>
                <br v-else />
                <ul>
                  <li v-for="(feature, index) in plan.features" :key="index">
                    <span class="icon has-text-success">
                      <i class="fas fa-check"></i>
                    </span>
                    <span class="txt">{{ feature.txt }}</span>
                  </li>
                </ul>
                <!--   @click="btBuy(plan.id)" -->
                <router-link :to="plan.buyUrl">
                  <div
                    :data-plan="plan.id"
                    :class="{ 'is-success': plan.featured, 'is-info': !plan.featured }"
                    class="button is-fullwidth"
                  >
                    Select
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <!--     -->

        <br /><br />
      </div>
    </div>
  </span>
</template>
<script>
//import numAbv from "@/components/e/numAbv.vue";

/*
const plans = {}; //plans ids
plans.turbo = { monthly: "646345", yearly: "588529" };
plans.supersonic = { monthly: "645440", yearly: "588530" };
plans.super100 = { monthly: "653898", yearly: "657023" };
*/
export default {
  //name: 'Home',
  components: {
    // numAbv, //bottomBar,
  },
  data() {
    return {
      yearly: true, // default
    };
  },
  props: ["hideFree", "business", "coupon"],
  methods: {
    btBuy(p) {
      // alert(p);
      /*
      var me = this;
      var i = plans[p];

      var plans = this.plans;
      var plan = plans.find((p) => p.id == i);

      var yearly = this.yearly; // ? i.yearly : i.monthly;

      var u = "/subscribe/confirm?type=creator&bot=" + this.params.bot + "&plan=" + plan.id;

      //if yearly
      if (yearly) {
        u += "&yearly=1";
      }
      //reoutr redir
      this.$router.push(u);
*/
      //    alert(id)
      /*
      var pad = {
        product: id, //the id
        successCallback: function (data) {
          console.log(data);
          //alert('Thanks for your purchase.');
          //mixpanel.track("Paid plan");
          //fbq('track', 'Paid Plan');
          //step(6); //final step!
          //window.location('/profile?upgraded=1');
          //alert('PURCHASED TODO: redirect. + Toast!');
          me.$buefy.toast.open({
            message: "Congrats on upgrading!",
            type: "is-success",
          });
          me.$router.push("/");
        },
        closeCallback: function (data) {
          console.log(data);
          //alert('Your purchase has been cancelled, we hope to see you again soon!');
        },*/
      // };
      /*  if(emailLocal){
          pad.email = emailQ;
        }
      pad.email = this.profile.email;

      pad.country = this.profile.userCountry;

      pad.disableLogout = true; //Disable the ability for a user to logout/change email once on the checkout.
      pad.allowQuantity = false;
      pad.modifier_recurring = false;
      //pad.modifier_amount = 55;

      pad.coupon = this.coupon; // TODO: or querry coupon param?

      //  pad.prices= [ 	"USD:19.99", 	"EUR:15.99"   ]
      console.log(pad);

      // Paddle.Checkout.open(pad);
*/
      //duh
    },
    fetchData(event) {
      //
    },
  },
  computed: {
    p() {
      return this.$store.main.getters.profile || {};
    },
    profile() {
      return this.$store.main.getters.profile || {};
    },
    plans() {
      var pp = [
        {
          id: "basic",
          title: "Pay as you go",
          image: "https://cdn1.invitation.codes/imgv1/ouch/marginalia/marginalia-delivery.png",
          price: "Free",
          features: [
            { txt: "Private or public bot" },
            { txt: "AI messaging & posting" },

            //  { txt: "Unlimited users" }, //Share bots with unlimited users
            { txt: "Sell subscriptions" },
            { txt: "Only pay computing cost" },
            //    { txt: "Monetize Your bots" },
          ],
          isFree: true,
        },
        {
          id: "plus",
          featured: true,
          title: "Plus",
          image: "https://cdn1.invitation.codes/imgv1/ouch/marginalia/marginalia-virtual-reality-1.png",
          monthlyPrice: 20,
          // yearlyPrice: 96,
          dataPlan: "588529",
          dataPlanMonth: "646345",
          features: [
            { txt: "Receive Donations" }, // "Earn Tips for Your Bot"
            { txt: "Verified profile badge" },
            { txt: "Priority Access to New Features" },
            { txt: "Discord Integration" },
            { txt: "Increased Post Automation Capacity" },
          ],
        },
        {
          id: "supersonic",
          title: "Pro",
          image: "https://cdn1.invitation.codes/imgv1/ouch/marginalia/marginalia-car-rental.png",
          monthlyPrice: 50,
          //  yearlyPrice: 288,
          dataPlan: "588530",
          dataPlanMonth: "645440",
          features: [
            { txt: "Embedding and Widgets" },
            // { txt: "Unlimited Word Capacity" },
            { txt: "Use your own API keys" }, //  { txt: "GPT-4 Access" },
            { txt: "Priority Support" },
            { txt: "Advanced Analytics and Insights" },
            { txt: "Customized API Key" },
          ],
        },
        {
          id: "super100",
          title: "Max",
          image: "https://cdn1.invitation.codes/imgv1/super100.png",
          monthlyPrice: 200,
          //  yearlyPrice: 960,
          dataPlan: "657023",
          dataPlanMonth: "653898",
          features: [
            { txt: "API Access" },
            { txt: "Branding Customization" },
            { txt: "External data-sources" },
            //  { txt: "Use your own API keys" },// { txt: "Flexible Invoicing" },
            { txt: "Extensive 3rd Party Integration" },
            //{ txt: "Advanced Data-Indexing" },
          ],
        },
      ];

      //compute yearly price based on monthly price
      pp = pp.map((p) => {
        p.yearlyPrice = Math.round(p.monthlyPrice * 12 * 0.8);

        var u = "/sub/confirm?bot=" + this.$route.params.bot + "&type=creator&plan=" + p.id;

        //if yearly
        if (this.yearly) {
          u += "&yearly=1";
        }
        p.buyUrl = u;
        return p;
      });
      return pp;
    },
  },
  watch: {
    id: function (from, to) {
      //necessary to refresh on url changes.
      // this.fetchData()
    },
  },
  mounted() {
    /* to be executed when mounted */
    //this.fetchData();
    this.$loadScript("https://cdn.paddle.com/paddle/paddle.js")
      .then(() => {
        // Script is loaded, do something
        console.log("PADDLE loaded");
        //  Paddle.Setup({ vendor: 109478 }); // promocodeo
      })
      .catch(() => {
        console.log("PADDLE FAILURE");
        // Failed to fetch script
      });
  },
};
</script>

<style>
/* fix from PADDLE support team  */
html.mvc__a.mvc__lot.mvc__of.mvc__classes.mvc__to.mvc__increase.mvc__the.mvc__odds.mvc__of.mvc__winning.mvc__specificity,
html.mvc__a.mvc__lot.mvc__of.mvc__classes.mvc__to.mvc__increase.mvc__the.mvc__odds.mvc__of.mvc__winning.mvc__specificity
  > body {
  position: static !important;
}

.plans {
}
.plan {
  margin-top: 20px;
  position: relative;
}
.plan img {
  height: 190px;
  margin: auto;
  display: block;
}
.plan .wr {
  position: relative;
  height: 100%;
  min-height: 22em;
}

.plan .wr {
}
.plan .button {
  margin-top: 20px;
  position: absolute;
  bottom: 0px;
  margin-right: 50px;
}

.plan {
  font-size: 0.7rem;
}
.plan-subtitle {
  font-style: italic;
  opacity: 0.6;
  font-size: 0.7rem;
}

.plan.featured {
  margin-top: 0;
}
.plan.featured .wr {
  min-height: 27em;
}
.plan.featured .button {
  margin-top: 20px;
  font-weight: bold;
}
.featured .button {
  padding-top: 30px;
  padding-bottom: 30px;
}

.plan ul {
  margin-bottom: 30px;
}

.smaller3 .plan {
  padding: 15px;
}

/*
  .plan .monthly, body.annual .plan .monthly{
  display: none;
}
body.monthly .plan .annual{
  display: none;
}
body.monthly .plan .monthly{
  display: block;
}
*/
.price-year {
  display: none;
}
</style>
